<template>
  <h3>Заявка на подключение самозанятости отправлена</h3>
</template>

<script lang="ts">
import {onMounted, ref} from "vue";
// @ts-ignore
import BankFormService, {StoreResponse} from "@/services/employee/BankFormService";
// @ts-ignore
import EmployeeService, {Stage, StageResponse} from "@/services/employee/EmployeeServise";
// @ts-ignore
import router from "@/router";

export default {
  setup() {
    async function checkStage() {
      const response = await EmployeeService.stage();
      const data = response.data.data as StageResponse

      if (data.next != Stage.Confirmation) {
        await router.push({name: `employee/${data.next}`})
      }
    }

    onMounted(async () => {
      await checkStage();
    })
  }
}
</script>